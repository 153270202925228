import { IAppConfig } from './types'
import loadMainNavigation from './loadMainNavigation'
import loadMainShortcuts from './loadMainShortcuts'
import loadMobileTabNavigation from './loadMobileTabNavigation'
import { loadScheduleSearchShortcuts } from './schedule_search_shortcuts'
import { appConfig as cachedAppConfig } from './cachedAppConfig'
import loadFanServices from './loadFanServices'

// loading the cachedAppConfig, instead of live data from prismic
// process.env.NODE_ENV === 'production' || process.env.LOAD_LOCAL_CONFIG === 'true'
// env check does not work properly
const configCacheActive = false

const fetchAppConfig = async (): Promise<IAppConfig> => {
  const main_navigation = await loadMainNavigation()
  const fan_services = await loadFanServices()
  const main_shortcuts = await loadMainShortcuts()
  const mobile_tab_navigation = await loadMobileTabNavigation()
  const schedule_search_shortcuts = await loadScheduleSearchShortcuts()

  return {
    main_navigation,
    fan_services,
    main_shortcuts,
    mobile_tab_navigation,
    schedule_search_shortcuts
  }
}

const loadAppConfig = async (): Promise<IAppConfig> => {
  if (configCacheActive) {
    return Promise.resolve(cachedAppConfig)
  } else {
    return await fetchAppConfig()
  }
}

export { fetchAppConfig, loadAppConfig }
